.w-md-editor .title {
  line-height: unset;
  font-size: unset;
  font-weight: unset;
  color: #d4d4d4 !important;
}

.w-md-editor ul {
  line-height: 1;
}

.text-editor .w-md-editor-bar svg {
  display: none;
}

.text-editor .w-md-editor-bar {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  height: 11px;
  cursor: row-resize;
  background-color: #37414b;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  position: relative;
}

.text-editor em {
  font-style: italic;
}

.text-editor .wmde-markdown hr {
  border-top: 1px solid #dee5ed;
}

.text-editor .wmde-markdown ol {
  list-style: decimal;
}

.text-editor .w-md-editor-show-live {
  /* Hide menu bar buttons to prevent accidental delete */
  z-index: 20;
}

.text-editor .w-md-editor-toolbar {
  background-color: #37414b;
  border-bottom: 1px solid gray;
}

.text-editor .w-md-editor-toolbar li button {
  color: #d4d4d4;
}

.text-editor .w-md-editor-content {
  background-color: #202123;
}

.text-editor .w-md-editor,
.text-editor .w-md-editor .w-md-editor-text-pre {
  color: #d4d4d4;
}

.text-editor .w-md-editor-text-pre .bold {
  color: unset;
}

.text-editor .token.list.punctuation {
  background-color: unset;
}

.text-editor .wmde-markdown code {
  background-color: rgba(10, 10, 10, 0.5);
}

.text-editor .wmde-markdown pre {
  background-color: rgba(10, 10, 10, 0.2);
}

.text-editor .wmde-markdown pre code {
  background-color: unset;
  color: white;
  text-shadow: none;
}
