.add-cell {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in 0.1s;
  margin: 8px 0;
}

.add-cell:hover {
  opacity: 1;
}

.add-cell.force-visible {
  opacity: 1;
}

.add-cell .divider {
  position: absolute;
  top: 50%;
  bottom: 50%;
  border-bottom: 1px solid gray;
  right: 2.5%;
  left: 2.5%;
  width: 95%;
  z-index: -1;
}

.add-cell .add-buttons {
  display: flex;
  justify-content: center;
}

.add-cell .add-buttons button {
  margin: 0 20px;
}
